import React from 'react';
import Icon from '@mdi/react';
import { mdiMenuUp, mdiMenuDown } from '@mdi/js';
import './NumericInput.scss';

export const NumericInput = ({ className, value, onChange, min, max, step, ...props }) => {
  const handleChange = e => {
    onChange(e.target.value);
  };

  const handleStepUp = () => {
    onChange(Number(value) + 1);
  };
  const handleStepDown = () => {
    onChange(Number(value) - 1);
  };

  return (
    <div className="numeric-input-container">
      <input
        className={className}
        type="number"
        value={value}
        onChange={handleChange}
        min={min}
        max={max}
        step={step}
        {...props}
      />
      <span>%</span>
      <div className="steps-buttons">
        <button className="step-button">
          <Icon path={mdiMenuUp} size={0.7} onClick={handleStepUp} />
        </button>
        <button className="step-button">
          <Icon path={mdiMenuDown} size={0.7} onClick={handleStepDown} />
        </button>
      </div>
    </div>
  );
};
