import React, { useState, useRef } from 'react';
import Icon from '@mdi/react';
import { mdiSend } from '@mdi/js';
import { useStore } from '../../utils/store';
import Modal from '../Modal/Modal';
import { sendAnalytics, EVENTS } from '../../utils/analytics';

import './Feedback.scss';

const Feedback = ({ onClose }) => {
  const { store } = useStore();
  const [rating, setRating] = useState();
  const textareaRef = useRef(null);
  const emailRef = useRef(null);

  const handleRating = i => {
    setRating(i);
    sendAnalytics({ ...EVENTS.select_rating, additional_info: i });
  };

  const handleFeedback = () => {
    sendAnalytics({
      ...EVENTS.submit_answer_to_feedback_question,
      additional_info: textareaRef.current.value
    });
    textareaRef.current.value = 'Thanks, your feedback was sent :)';
    setTimeout(() => {
      textareaRef.current.value = '';
    }, 3000);
  };

  const handleEmail = () => {
    sendAnalytics({
      ...EVENTS.submit_email_from_feedback,
      additional_info: emailRef.current.value
    });
    emailRef.current.value = 'Thanks!';
    setTimeout(() => {
      emailRef.current.value = '';
    }, 3000);
  };

  return (
    <Modal className="feedback" onClose={onClose} title="Rate the result">
      <div className="ratings">
        <div className="buttons">
          {[1, 2, 3, 4, 5].map(i => (
            <button
              key={i}
              className={i === rating ? 'active' : undefined}
              onClick={() => handleRating(i)}
            >
              {i}
            </button>
          ))}
        </div>
        <div className="labels">
          <span>Very unsatisfied</span>
          <span>Very satisfied</span>
        </div>
      </div>

      <h3>Did the app meet your expectations?</h3>
      <textarea className="input" ref={textareaRef} placeholder={'Let us know...'}></textarea>
      <button className="btn inverted send-feedback" onClick={handleFeedback}>
        <Icon path={mdiSend} size={1} />
      </button>

      <h3>Enter your email and hear about our new AI tools</h3>
      <div className="email">
        <input
          className="input email-input"
          type="email"
          ref={emailRef}
          placeholder="your@email.com"
          defaultValue={store.emailAddress}
          required
        />
        <button className="btn inverted send-email" onClick={handleEmail}>
          <Icon path={mdiSend} size={1} />
        </button>
      </div>

      <a
        href="https://cloudinary.com/users/register_free?ref=edit_at_scale"
        rel="noreferrer noopener"
        target="_blank"
        className="sign-up"
        onClick={() => sendAnalytics(EVENTS.sign_up_from_feedback)}
      >
        Free sign up to Cloudinary
      </a>
    </Modal>
  );
};

export default Feedback;
