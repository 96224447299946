import { createContext, useReducer, useContext } from 'react';
import { BACKGROUNDS, OVERLAYS } from '../const';
import { isSmallScreen } from './isTouch';

export const initialStore = {
  uploadInProgress: false,
  activeIndex: 0,
  backgrounds: BACKGROUNDS,
  legalAgreement: false,
  showLegal: false,
  showFeedback: false,
  showShare: false,
  showShareResult: false,
  showDownload: false,
  emailAddress: '',
  sidepanel: isSmallScreen() ? false : 'background',
  imageData: [],
  batchFolderName: undefined,
  transformation: [{ background: { publicId: 'original' } }],
  redoData: [],
  overlays: OVERLAYS
};

export const Store = createContext(initialStore);

export const useStore = () => useContext(Store);

export function StoreProvider(props) {
  const [store, updateStore] = useReducer(
    (state, payload) => ({ ...state, ...payload }),
    initialStore
  );

  return <Store.Provider value={{ store, updateStore }}>{props.children}</Store.Provider>;
}
