import React from 'react';
import Icon from '@mdi/react';
import { mdiClose } from '@mdi/js';
import { useStore } from '../../utils/store';
import BackgroundPanel from '../BackgroundPanel';
import { ImageOverlayPanel } from '../ImageOverlayPanel/ImageOverlayPanel';
import ComingSoon from '../ComingSoon';
import Tooltipped from '../Tooltipped/Tooltipped';

import './SidePanel.scss';

const panels = {
  text: ComingSoon,
  background: BackgroundPanel,
  image: ImageOverlayPanel
};

const SidePanel = () => {
  const { store, updateStore } = useStore();
  const Panel = panels[store.sidepanel];

  const closeSidePanel = () => {
    updateStore({ sidepanel: false });
  };

  return (
    <aside className={`sidepanel ${store.sidepanel ? 'open' : ''}`}>
      <div className="sidepanel-inner">
        <Tooltipped
          el="button"
          className="sidepanel-toggle"
          title="Close panel"
          onClick={closeSidePanel}
          tooltipProps={{
            content: 'Close panel',
            place: 'bottom'
          }}
        >
          <Icon path={mdiClose} size={1} />
        </Tooltipped>
        {Panel && <Panel />}
      </div>
    </aside>
  );
};

export default SidePanel;
