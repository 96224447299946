import React, { useState } from 'react';
import Icon from '@mdi/react';
import { mdiCloseCircle, mdiPlus, mdiRefresh } from '@mdi/js';
import Tooltipped from '../Tooltipped/Tooltipped';
import { DEFAULT_OVERLAYS } from '../../const';
import { useStore } from '../../utils/store';
import DropZone from '../DropZone/DropZone';
import './ImageOverlayPanel.scss';
import ImageLayer from '../ImageLayer/ImageLayer';
import { EVENTS, sendAnalytics } from '../../utils/analytics';
import { useTransformation } from '../../utils/useTransformation';
import { PlacementPicker } from './PlacementPicker/PlacementPicker';
import { NumericInput } from '../NumericInput/NumericInput';
import { RangeInput } from '../RangeInput/RangeInput';
import { useDebouncedCallback } from 'use-debounce';

export const PLACEMENTS = {
  northWest: 'north_west',
  north: 'north',
  northEast: 'north_east',
  west: 'west',
  center: 'center',
  east: 'east',
  southWest: 'south_west',
  south: 'south',
  southEast: 'south_east'
};

export const ImageOverlayPanel = () => {
  const { store, updateStore } = useStore();
  const { setTransformation } = useTransformation();
  const [selectedPlacement, setSelectedPlacement] = useState(
    store?.transformation.at(-1)?.overlay?.placement || PLACEMENTS.center
  );
  const [size, setSize] = useState(store?.transformation.at(-1)?.overlay?.size || 50);
  const [opacity, setOpacity] = useState(store?.transformation.at(-1)?.overlay?.opacity || 50);
  const activeTransformation = store?.transformation.at(-1);
  const activeOverlay = activeTransformation?.overlay;

  const setOverlay = overlay => {
    setTransformation({
      overlay: {
        ...overlay,
        placement: selectedPlacement,
        size,
        opacity
      }
    });
  };

  const onSelectOverlay = overlay => {
    setOverlay(overlay);
    sendAnalytics({ ...EVENTS.overlay_upload_done });
  };

  const resetOverlays = () => {
    updateStore({
      overlays: DEFAULT_OVERLAYS
    });
  };

  const removeItem = index => {
    const newOverlays = [...store.overlays];
    newOverlays.splice(index, 1);
    updateStore({
      overlays: newOverlays
    });
  };

  const onOverlayDrop = async acceptedFiles => {
    sendAnalytics({ ...EVENTS.overlay_upload, additional_info: acceptedFiles.length });
  };

  const onOverlayUpload = async uploadInfo => {
    updateStore({
      uploadInProgress: false,
      overlays: [...uploadInfo, ...store.overlays]
    });

    sendAnalytics({ ...EVENTS.overlay_upload_done, additional_info: uploadInfo[0].publicId });
  };

  const handleCircleClick = placement => {
    setSelectedPlacement(placement);

    if (activeOverlay?.publicId)
      setTransformation({
        overlay: {
          ...activeOverlay,
          placement
        }
      });
  };

  const setSizeInTransformation = () =>
    setTransformation({
      overlay: {
        ...activeOverlay,
        size
      }
    });
  const [debouncedSetSize] = useDebouncedCallback(setSizeInTransformation, 300);

  const handleSizeChange = size => {
    setSize(size);
    if (activeOverlay?.publicId) debouncedSetSize();
  };
  const setOpacityInTransformation = () =>
    setTransformation({
      overlay: {
        ...activeOverlay,
        opacity
      }
    });
  const [debouncedSetOpacity] = useDebouncedCallback(setOpacityInTransformation, 300);

  const handleOpacityChange = opacity => {
    setOpacity(opacity);
    if (activeOverlay?.publicId) debouncedSetOpacity();
  };

  return (
    <div className="overlay-panel">
      <div className="overlay-wrp">
        <Tooltipped
          el="button"
          className="reset-overlays"
          title="Reset to default overlays"
          onClick={resetOverlays}
          tooltipProps={{
            content: 'Reset to default overlays',
            place: 'bottom'
          }}
        >
          <Icon path={mdiRefresh} size={1} />
        </Tooltipped>
        <h3>Choose an overlay</h3>
        <div className="overlays">
          <div className={`overlay ${!activeOverlay?.publicId ? 'active' : ''}`}>
            <button className="select none" onClick={() => onSelectOverlay(null)}>
              <span>None</span>
            </button>
          </div>
          <div className="upload">
            <DropZone
              text=" "
              onDrop={onOverlayDrop}
              onUpload={onOverlayUpload}
              maxFiles={1}
              icon={<Icon path={mdiPlus} size={1.4} />}
            />
          </div>
          {store.overlays?.map((overlay, index) => (
            <div
              className={`overlay ${overlay.publicId === activeOverlay?.publicId ? 'active' : ''}`}
              key={overlay.publicId}
            >
              <button className="remove" onClick={() => removeItem(index)}>
                <Icon path={mdiCloseCircle} size={1} />
              </button>
              <button
                className="select"
                onClick={() => onSelectOverlay(overlay)}
                title={overlay.prompt || ''}
              >
                <ImageLayer publicId={overlay.publicId} tx="t_thumb" />
              </button>
            </div>
          ))}
        </div>
      </div>
      <div className="overlay-parameters with-seperator">
        <h3>Placement</h3>
        <PlacementPicker onCircleClick={handleCircleClick} selectedPlacement={selectedPlacement} />
      </div>
      <div className="overlay-parameters with-seperator">
        <h3>Size</h3>
        <div className="field-container">
          <NumericInput
            type="number"
            min="1"
            max="99"
            step="0.01"
            value={size}
            onChange={handleSizeChange}
          />
          <RangeInput
            type="range"
            min="1"
            max="99"
            step="0.01"
            value={size}
            onChange={handleSizeChange}
          />
        </div>
        <h3>Opacity</h3>
        <div className="field-container">
          <NumericInput
            type="number"
            min="1"
            max="100"
            step="0.01"
            value={opacity}
            onChange={handleOpacityChange}
          />
          <RangeInput
            type="range"
            min="1"
            max="100"
            step="0.01"
            value={opacity}
            onChange={handleOpacityChange}
          />
        </div>
      </div>
    </div>
  );
};
