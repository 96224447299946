export function isObject(item) {
  return typeof item === 'object' && !Array.isArray(item) && item !== null;
}

export function getStrBetween(str, start, end) {
  return str.split(start).pop().split(end)[0];
}

export function getBgPath(bg, key) {
  const backgrounds = JSON.parse(localStorage.getItem('backgrounds'));
  const activeBg = backgrounds.find(b => (b.publicId || b.bgId) === bg);
  return activeBg && activeBg.urls && activeBg.urls[key || Object.keys(activeBg.urls)[0]];
}

export function setBgPath(bg, key, url) {
  const backgrounds = JSON.parse(localStorage.getItem('backgrounds'));
  const bgIndex = backgrounds.findIndex(b => (b.publicId || b.bgId) === bg);
  if (bgIndex < 0) return;
  backgrounds[bgIndex].urls = {
    ...backgrounds[bgIndex].urls,
    [key]: url
  };
  localStorage.setItem('backgrounds', JSON.stringify(backgrounds));
}
