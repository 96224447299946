import Icon from '@mdi/react';
import Select from 'react-select';
import React, { useState } from 'react';
import { mdiEyedropperVariant } from '@mdi/js';
import './ColorPicker.scss';
import Modal from '../Modal/Modal';
import { EVENTS, sendAnalytics } from '../../utils/analytics';
import { useTransformation } from '../../utils/useTransformation';

const options = [
  { value: 'horizontal', label: 'Horizontal Gradient' },
  { value: 'radial', label: 'Radial gradient' },
  { value: 'vertical', label: 'Vertical Gradient' },
  { value: 'color', label: 'Color' }
];

const theme = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: 'var(--contrastLow)',
    primary: 'var(--contrastLow)',
    neutral0: 'var(--surfaceAlt)',
    neutral80: 'var(--contrastHigh)',
    neutral70: 'var(--contrastHigh)',
    neutral90: 'var(--contrastHigh)',
    neutral50: 'var(--contrastHigh)',
    neutral60: 'var(--contrastHigh)'
  }
});

export const ColorPicker = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const [color1, setColor1] = useState('#FF595A');
  const [color2, setColor2] = useState('#4F01D3');
  const { setTransformation } = useTransformation();

  const handleColorChange = e => {
    const { name, value } = e.target;
    if (name === 'color1') {
      setColor1(value);
    } else if (name === 'color2') {
      setColor2(value);
    }
  };

  const generateCSSBackground = () => {
    const selectedMode = selectedOption.value;

    if (selectedMode === 'color') return color1;
    if (selectedMode === 'radial') return `radial-gradient(${color1}, ${color2})`;
    const direction = selectedMode === 'horizontal' ? 'to right' : 'to bottom';
    return `linear-gradient(${direction}, ${color1}, ${color2})`;
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = e => {
    if (e) {
      e.stopPropagation();
    }
    setIsModalOpen(false);
  };

  const handleChangeColorMode = option => {
    setSelectedOption(option);
  };

  const setBackground = () => {
    const selectedMode = selectedOption.value;

    setTransformation({
      removeBackground: true,
      background: {
        publicId: 'color',
        color1: color1.replace('#', ''),
        ...(selectedMode !== 'color' && {
          color2: color2.replace('#', ''),
          isHorizontal: selectedMode === 'horizontal',
          isRadial: selectedMode === 'radial'
        })
      }
    });
    sendAnalytics(EVENTS.click_on_color_bg);
    setIsModalOpen(false);
  };

  return (
    <div className="select color-picker" style={{ background: generateCSSBackground() }}>
      <button className="color-picker-button" onClick={openModal}>
        Color
      </button>
      {isModalOpen && (
        <Modal className="color-picker-modal" onClose={closeModal} title={'Edit color'}>
          <Select
            options={options}
            theme={theme}
            value={selectedOption}
            onChange={handleChangeColorMode}
          />
          <div className="color-pickers-buttons">
            <label className="picker-button" style={{ background: color1 }}>
              <Icon path={mdiEyedropperVariant} size={0.9} />
              <input type="color" name="color1" value={color1} onChange={handleColorChange} />
            </label>
            <label className="picker-button" style={{ background: color2 }}>
              <Icon path={mdiEyedropperVariant} size={0.9} />
              <input type="color" name="color2" value={color2} onChange={handleColorChange} />
            </label>
          </div>
          <div className="color-preview" style={{ background: generateCSSBackground() }}></div>
          <div className="color-picker-footer">
            <button className="btn apply-btn" onClick={setBackground}>
              Apply
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
};
