export const PROMPTS = [
  'a 3d render of a tabletop with a circle plate, in front of a large window to the blue sky, high resolution, detailed, abstract, ambient occlusion, octane render, eye level, close-up',

  'a highly detailed matte painting of grassy hills by studio ghibli, makoto shinkai, by artgerm, by wlop, by greg rutkowski, volumetric lighting, octane render, 4 k resolution, depth of field',

  'An empty plate in a Parisian luxurious interior penthouse kitchen table, dark walls, wooden panels. close-up of the table top',

  'a tree, hyper realistic, epic composition, cinematic, landscape vista photography by Carr Clifton & Galen Rowell, detailed landscape painting by Ivan Shishkin, rendered in Enscape, 4k detailed post processing, unreal engine',

  'the greek coastline in the style of David Hockney, high quality, beautiful lighting, masterpiece, best quality',

  'in a cave, natural light, lush plants and flowers, elegant, intricate, fantasy, atmospheric lighting',

  'closeup profile portrait of a 1 9 5 0 s diner, depth of field, detailed and intricate environment, golden hour, 8 k resolution, hyperrealistic, octane render',

  'a beautiful picture of the ocean, sunset in the evening, sparkling light blue sea water, beach, many golden twinkling crystal covering the beach, beautiful colorful clouds hd',

  'hyper realistic matte painting of desert dunes with blue light outlines',

  'beautiful oil painting of a subterranean cyberpunk space with abandoned houses, trees, water, fantasy, unreal engine 5 quality render',

  'surreal flowers under the moonlight, soft render, volumetric lighting, 3d grainy aesthetic illustration, editorial magazine cover'
];
