import React, { useRef } from 'react';
import { useStore } from '../../utils/store';
import ImageLayer from '../ImageLayer';

import './Canvas.scss';

const Canvas = () => {
  const { store } = useStore();
  const canvasRef = useRef(null);

  const { imageData, activeIndex } = store;
  const activeImage = imageData[activeIndex] || imageData[0];

  return (
    <div ref={canvasRef} className="canvas">
      <ImageLayer publicId={activeImage.publicId} />
    </div>
  );
};

export default Canvas;
