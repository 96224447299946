import React, { useRef, useEffect } from 'react';
import './RangeInput.scss';

export const RangeInput = ({ value, onChange, min, max, step }) => {
  const inputRef = useRef();

  useEffect(() => {
    inputRef.current.style.backgroundSize = ((Number(value) - min) * 100) / (max - min) + '% 100%';
  }, [value, min, max]);

  const handleChange = e => {
    onChange(e.target.value);
  };

  return (
    <input
      ref={inputRef}
      className="range-input"
      type="range"
      value={value}
      onChange={handleChange}
      min={min}
      max={max}
      step={step}
    />
  );
};
