import React, { useEffect, useRef, useCallback } from 'react';
import { useLocalStorage } from 'react-use';
import { v4 as uuidv4 } from 'uuid';
import Icon from '@mdi/react';
import { mdiCloseCircle, mdiPlus, mdiRefresh, mdiAutoFix } from '@mdi/js';
import { ReactComponent as TipsIcon } from '@material-design-icons/svg/outlined/tips_and_updates.svg';
import Toggle from 'react-toggle';
import { DEFAULT_BACKGROUNDS } from '../../const';
import { PROMPTS } from '../../utils/prompts';
import { useStore } from '../../utils/store';
import { useTransformation } from '../../utils/useTransformation';
import DropZone from '../DropZone';
import Tooltipped from '../Tooltipped/Tooltipped';
import ImageLayer from '../ImageLayer';
import { ColorPicker } from '../ColorPicker/ColorPicker';
import { sendAnalytics, EVENTS } from '../../utils/analytics';

import 'react-toggle/style.css';
import './BackgroundPanel.scss';
import { isSmallScreen } from '../../utils/isTouch';

const BackgroundPanel = () => {
  const { store, updateStore } = useStore();
  // eslint-disable-next-line no-unused-vars
  const [storage, setStorage] = useLocalStorage('backgrounds', store.backgrounds);
  const { setTransformation } = useTransformation();
  const textareaRef = useRef(null);
  const backgroundsRef = useRef(store.backgrounds);

  const activeTransformation = store.transformation.at(-1);
  const activeBackground = activeTransformation?.background;
  const shadowChecked = store.transformation.at(-1)?.addShadow || false;

  const setBackground = useCallback(
    bg => {
      // Bail if it's already the active background
      if ((bg.publicId || bg.bgId) === (activeBackground.publicId || activeBackground.bgId)) return;
      const pid = bg?.publicId;
      if (pid === 'original') {
        setTransformation({
          removeBackground: false,
          background: bg
        });
      } else if (pid === 'none') {
        sendAnalytics(EVENTS.remove_background);
        setTransformation({
          removeBackground: true,
          background: bg
        });
      } else if (bg.prompt) {
        sendAnalytics({ ...EVENTS.clicked_generate_bg, additional_info: bg.prompt });
        const cached = store.transformation.find(t => t.background.bgId === bg.bgId);
        setTransformation(
          cached || {
            removeBackground: true,
            background: bg
          }
        );
      } else {
        sendAnalytics({ ...EVENTS.click_sample_bg_image, additional_info: bg.publicId });
        setTransformation({
          removeBackground: true,
          background: bg
        });
      }

      if (isSmallScreen()) {
        updateStore({ sidepanel: false });
      }
    },
    [activeBackground, setTransformation, store.transformation, updateStore]
  );

  useEffect(() => {
    // If we have new backgrounds, set the new (first) one as active
    if (
      store.backgrounds?.length > 0 &&
      store.backgrounds.length > backgroundsRef.current.length && // list got bigger
      activeBackground !== store.backgrounds[0] && // prevent loops
      backgroundsRef.current[0] !== store.backgrounds[0] // first was changed
    ) {
      setBackground(store.backgrounds[0]);
    }
    if (store.backgrounds !== backgroundsRef.current) {
      // update ref
      backgroundsRef.current = store.backgrounds;
      setStorage(store.backgrounds);
    }
  }, [setBackground, setStorage, activeBackground, store.backgrounds]);

  const handleGenerative = prompt => {
    prompt = prompt || textareaRef.current.value;

    textareaRef.current.value = 'Generating, hold on...';
    textareaRef.current.blur();

    onBackgroundUpload([{ prompt, bgId: uuidv4() }]);

    textareaRef.current.value = prompt;
  };

  const handleShadow = e => {
    const alreadyHasShadow = store.transformation.at(-1)?.addShadow;
    let addShadow = true;
    if (alreadyHasShadow) {
      addShadow = false;
      sendAnalytics(EVENTS.shadow_generation_toggle_off);
    } else {
      addShadow = true;
      sendAnalytics(EVENTS.shadow_generation_toggle_on);
    }
    setTransformation({
      addShadow
    });
  };

  const onBackgroundUpload = async uploadInfo => {
    updateStore({
      uploadInProgress: false,
      backgrounds: [...uploadInfo, ...store.backgrounds]
    });

    sendAnalytics({ ...EVENTS.background_upload_done, additional_info: uploadInfo[0].publicId });
  };

  const onBackgroundDrop = async acceptedFiles => {
    sendAnalytics({ ...EVENTS.background_upload, additional_info: acceptedFiles.length });
  };

  const removeItem = index => {
    const newBackgrounds = [...store.backgrounds];
    newBackgrounds.splice(index, 1);
    updateStore({
      backgrounds: newBackgrounds
    });
  };

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      handleGenerative();
    }
  };

  const selectBackground = bg => {
    setBackground(bg);
    if (bg.prompt) textareaRef.current.value = bg.prompt;
  };

  const resetBackgrounds = () => {
    updateStore({
      backgrounds: DEFAULT_BACKGROUNDS
    });
  };

  const handleRandomPrompt = () => {
    const prompt = PROMPTS[Math.floor(Math.random() * PROMPTS.length)];
    textareaRef.current.value = prompt;
  };

  return (
    <div className="background-panel">
      <div className="backgrounds-wrp">
        <Tooltipped
          el="button"
          className="reset-bgs"
          title="Reset to default backgrounds"
          onClick={resetBackgrounds}
          tooltipProps={{
            content: 'Reset to default backgrounds',
            place: 'bottom'
          }}
        >
          <Icon path={mdiRefresh} size={1} />
        </Tooltipped>
        <h3>Choose a background</h3>
        <div className="backgrounds">
          <div className={`background ${activeBackground.publicId === 'original' ? 'active' : ''}`}>
            <button className="select" onClick={() => setBackground({ publicId: 'original' })}>
              <span>Original</span>
            </button>
          </div>
          <div className={`background ${activeBackground.publicId === 'none' ? 'active' : ''}`}>
            <button className="select none" onClick={() => setBackground({ publicId: 'none' })}>
              <span>None</span>
            </button>
          </div>
          <div className={`color background ${activeBackground.publicId === 'color' ? 'active' : ''}`}>
            <ColorPicker />
          </div>
          <div className="upload">
            <DropZone
              text=" "
              onDrop={onBackgroundDrop}
              onUpload={onBackgroundUpload}
              maxFiles={1}
              icon={<Icon path={mdiPlus} size={1.4} />}
            />
          </div>
          {store.backgrounds?.map((bg, index) => (
            <div
              className={`background ${
                (bg.publicId || bg.bgId) === (activeBackground.publicId || activeBackground.bgId)
                  ? 'active'
                  : ''
              }`}
              key={bg.bgId || bg.publicId}
            >
              <button className="remove" onClick={() => removeItem(index)}>
                <Icon path={mdiCloseCircle} size={1} />
              </button>
              <button
                className="select"
                onClick={() => selectBackground(bg)}
                title={bg.prompt || ''}
              >
                {bg.bgId ? (
                  <ImageLayer tx={{ background: bg }} />
                ) : (
                  <ImageLayer publicId={bg.publicId} tx="t_thumb" />
                )}
              </button>
            </div>
          ))}
        </div>
      </div>

      <div className="shadow">
        <h3>Generate shadow</h3>
        <Toggle
          onChange={handleShadow}
          checked={shadowChecked && !activeBackground.prompt}
          disabled={!!activeBackground.prompt}
        />
      </div>

      <div className="generative with-seperator">
        <h3>Generate background</h3>
        <textarea
          ref={textareaRef}
          onKeyDown={handleKeyDown}
          placeholder={
            'A photograph of summer in paris, ultra-realistic, , ultra-sharp, octane render, 8k'
          }
        ></textarea>
        <button className="btn generate with-text" onClick={() => handleGenerative()}>
          <Icon path={mdiAutoFix} size={1} />
          Generate
        </button>
        <Tooltipped
          el="button"
          className="btn random"
          title="Genarate a random prompt"
          onClick={handleRandomPrompt}
          tooltipProps={{
            content: 'Genarate a random prompt',
            place: 'right'
          }}
        >
          <TipsIcon />
        </Tooltipped>
      </div>
    </div>
  );
};

export default BackgroundPanel;
