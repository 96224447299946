import React from 'react';
import SidePanel from '../SidePanel';
import Canvas from '../Canvas';
import AssetsBar from '../AssetsBar';

import './Editor.scss';

const Editor = () => {
  return (
    <div className="editor">
      <SidePanel />
      <Canvas />
      <AssetsBar />
    </div>
  );
};

export default Editor;
