import React from 'react';
import { useDropzone } from 'react-dropzone';
import { useStore } from '../../utils/store';
import { isTouch } from '../../utils/isTouch';
import { UPLOAD_IMAGE_ENDPOINT } from '../../const';
import { ReactComponent as BulkUploadIcon } from './bulk_icon.svg';

import './DropZone.scss';

const DropZone = props => {
  const { updateStore } = useStore();

  const onUpload = async uploadInfo => {
    const uploaded = uploadInfo.filter(i => i).map(i => ({ publicId: i }));

    props.onUpload(uploaded);
  };

  const onDrop = async acceptedFiles => {
    updateStore({ uploadInProgress: true });

    if (props.onDrop) {
      props.onDrop(acceptedFiles);
    }

    const formData = new FormData();
    acceptedFiles.forEach((file, i) => {
      formData.append(`image${i}`, file);
    });

    if (props.requestData) {
      Object.entries(props.requestData).forEach(([key, value]) => {
        formData.append(key, value);
      });
    }

    fetch(UPLOAD_IMAGE_ENDPOINT, {
      method: 'POST',
      body: formData
    })
      .then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
      })
      .then(data => {
        onUpload(data);
      })
      .catch(error => {
        console.error(error);
      });
  };

  const { getRootProps, getInputProps, isDragActive, fileRejections } = useDropzone({
    maxFiles: props.maxFiles || 10,
    accept: { 'image/*': [] },
    onDrop
  });

  const errors = fileRejections.map(i => i.errors.map(err => err.message)).flat();
  const uniqueErrors = Array.from(new Set(errors));

  const defaultText = isTouch() ? 'Tap to select images' : 'Drag and drop images or click';

  return (
    <div {...getRootProps()} className={`dropzone ${isDragActive ? 'active' : ''}`} title={defaultText}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <p>Drop the image here</p>
      ) : (
        <>
          {props.icon ? props.icon : <BulkUploadIcon />}
          <p>{props.text ? props.text : defaultText}</p>
        </>
      )}
      {uniqueErrors.length > 0 && uniqueErrors.map(err => <p className="error">{err}</p>)}
    </div>
  );
};

export default DropZone;
