import React from 'react';
import './PlacementPicker.scss';
import { PLACEMENTS } from '../ImageOverlayPanel';

export const PlacementPicker = ({ selectedPlacement, onCircleClick }) => {
  return (
    <div className="placement-picker-container">
      <div className="middle-container">
        <div className="placement-matrix">
          {Object.values(PLACEMENTS).map((placement, i) => (
            <div
              key={`circle-${i}`}
              className="circle-container"
              onClick={() => onCircleClick(placement)}
            >
              <div className={`circle ${selectedPlacement === placement ? 'active' : ''}`}></div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
