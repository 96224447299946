import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Icon from '@mdi/react';
import { mdiUndo, mdiRedo } from '@mdi/js';
import { useStore } from '../../utils/store';
import { sendAnalytics, EVENTS } from '../../utils/analytics';
import Tooltipped from '../Tooltipped';

import { ReactComponent as BgRemoveIcon } from './bg_remove.svg';
import { ReactComponent as ImageIcon } from './image_overlay.svg';
import { ReactComponent as TextIcon } from './text_overlay.svg';

import './Footer.scss';

const Footer = () => {
  const { store, updateStore } = useStore();

  const setSidePanel = panel => {
    sentPanelAnalytics(panel);

    updateStore({
      sidepanel: panel
    });
  };

  const sentPanelAnalytics = panel => {
    if (panel === 'text') {
      sendAnalytics(EVENTS.click_on_text_overlay);
    } else if (panel === 'background') {
      sendAnalytics(EVENTS.click_on_edit_background);
    } else if (panel === 'image') {
      sendAnalytics(EVENTS.click_on_image_overlay);
    }
  };

  const handleUndo = () => {
    const currentIndex = store.transformation.length - 1;
    const lastAction = store.transformation[currentIndex];

    updateStore({
      transformation: store.transformation.slice(0, currentIndex),
      redoData: [...(store.redoData || []), lastAction]
    });

    sendAnalytics(EVENTS.undo);
  };

  const handleRedo = () => {
    const currentIndex = store.redoData.length - 1;
    const lastAction = store.redoData[currentIndex];

    updateStore({
      transformation: [...(store.transformation || []), lastAction],
      redoData: store.redoData.slice(0, currentIndex)
    });

    sendAnalytics(EVENTS.redo);
  };

  return (
    <footer className="footer">
      <Routes>
        <Route
          path="/edit"
          element={
            <div className="footer-inner">
              <Tooltipped
                el="button"
                className={`btn inverted light ${store.sidepanel === 'text' ? 'active' : ''}`}
                onClick={() => setSidePanel('text')}
                disabled={!store.imageData}
                title="Text Overlay"
                tooltipProps={{
                  content: 'Text Overlay (coming soon)',
                  offset: 18
                }}
              >
                <TextIcon />
                {/* <span className="label">Text</span> */}
              </Tooltipped>
              <Tooltipped
                el="button"
                className={`btn inverted light ${store.sidepanel === 'background' ? 'active' : ''}`}
                onClick={() => setSidePanel('background')}
                disabled={!store.imageData}
                title="Edit Background"
                tooltipProps={{
                  content: 'Edit Background',
                  offset: 18
                }}
              >
                <BgRemoveIcon />
                {/* <span className="label">Background</span> */}
              </Tooltipped>
              <Tooltipped
                el="button"
                className={`btn inverted light ${store.sidepanel === 'image' ? 'active' : ''}`}
                onClick={() => setSidePanel('image')}
                disabled={!store.imageData}
                title="Image Overlay"
                tooltipProps={{
                  content: 'Image Overlay',
                  offset: 18
                }}
              >
                <ImageIcon />
                {/* <span className="label">Image</span> */}
              </Tooltipped>
              <span>
                <button
                  className="btn inverted light"
                  onClick={handleUndo}
                  disabled={!store.transformation?.length}
                >
                  <Icon path={mdiUndo} size={1} />
                </button>
                <button
                  className="btn inverted light"
                  onClick={handleRedo}
                  disabled={!store.redoData?.length}
                >
                  <Icon path={mdiRedo} size={1} />
                </button>
              </span>
            </div>
          }
        />
      </Routes>
    </footer>
  );
};

export default Footer;
