import React from 'react';
import { useNavigate } from 'react-router-dom';
import useMount from 'react-use/lib/useMount';
import { useStore, initialStore } from '../../utils/store';
import { DEFAULT_ASSETS } from '../../const';
import { sendAnalytics, EVENTS } from '../../utils/analytics';
import ImageLayer from '../ImageLayer/ImageLayer';
import DropZone from '../DropZone';
import './Upload.scss';
import { getStrBetween } from '../../utils/utils';

const Upload = () => {
  const navigate = useNavigate();
  const { updateStore } = useStore();

  useMount(() => {
    // Reset app state
    updateStore(initialStore);
  });

  const onUpload = async uploadInfo => {
    const batchFolderName = getStrBetween(uploadInfo[0].publicId, 'EditorAtScale/', '/');

    sendAnalytics({...EVENTS.assets_upload_done, additional_info: batchFolderName,});

    updateStore({
      uploadInProgress: false,
      imageData: uploadInfo,
      ...(batchFolderName && { batchFolderName })
    });
    navigate({
      pathname: '/edit',
      search: window.location.search
    });
  };

  const onDrop = async acceptedFiles => {
    sendAnalytics({...EVENTS.assets_upload, additional_info: acceptedFiles.length,});
  };

  return (
    <div className="upload-page">
      <div className="intro">
        <div className="container">
          <h1>AI Batch Editor</h1>
          <h2>For Your Product Photos</h2>
          <h3>
            Transform your e-commerce business with Edit @ Scale, the ultimate batch editor for product photos.
            <br />
            Save time and effort by seamlessly enhancing multiple images at once with beautiful, unique backgrounds.
          </h3>
          <ImageLayer
            publicId="EditorAtScale/c7c1a83b-36a5-4d07-8e8c-7c03e673deb3/visuupk9eag0p5a236sr"
            tx="t_resize"
          />
        </div>
      </div>

      <div className="upload">
        <div className="container">
          <h4 className="try">Select multiple images to edit in bulk</h4>
          <DropZone onDrop={onDrop} onUpload={onUpload} />
          <h4>Or experiment with these 3 images</h4>
          <div className="samples">
            <button
              type="button"
              onClick={() => {
                sendAnalytics(EVENTS.choose_example_images);
                onUpload(DEFAULT_ASSETS);
              }}
            >
              {DEFAULT_ASSETS.map(ex => (
                <ImageLayer key={ex.publicId} publicId={ex.publicId} tx="t_thumb" alt={ex.alt} />
              ))}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Upload;
