import React, { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
// import { sendAnalytics, EVENTS } from '../../utils/analytics';
import { useStore } from '../../utils/store';
import Header from '../Header';
import Footer from '../Footer';
import Upload from '../Upload';
import Editor from '../Editor';
import Progress from '../Progress';

import './App.scss';

function App() {
  const { store } = useStore();

  useEffect(() => {
    const handleError = error => {
      // sendAnalytics({ ...EVENTS.error_msg, additional_info: `${error.type}: ${error.message}` });
    };
    window.addEventListener('error', handleError);
    return () => {
      window.removeEventListener('error', handleError);
    };
  }, []);

  return (
    <div className="App">
      <div className="main">
        <Header />
        <div className="content">
          <Routes>
            <Route path="/" element={store.uploadInProgress ? <Progress /> : <Upload />} />
            <Route
              path="/edit"
              element={
                store.imageData?.length ? (
                  <Editor />
                ) : (
                  <Navigate to={{ pathname: '/', search: window.location.search }} replace={true} />
                )
              }
            />
          </Routes>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default App;
