import JSZip from 'jszip';
import { saveAs } from 'file-saver';

export function downloadZip(imageUrls) {
  const zip = new JSZip();
  const promises = [];
  imageUrls.forEach((url, index) => {
    promises.push(
      fetch(url)
        .then(response => response.blob())
        .then(blob => {
          zip.file(`image${index}.jpg`, blob);
        })
    );
  });
  Promise.all(promises).then(() => {
    zip.generateAsync({ type: 'blob' }).then(content => {
      saveAs(content, 'images.zip');
    });
  });
}
