import React, { useEffect, useRef, useState } from 'react';
import Icon from '@mdi/react';
import { mdiDownload, mdiCheckboxMarked, mdiCheckboxBlankOutline } from '@mdi/js';
import { useStore } from '../../utils/store';
import { downloadZip } from '../../utils/downloadZip';
import { getImagePath } from '../../utils/cloudinary';
import Modal from '../Modal/Modal';
import { sendAnalytics, EVENTS } from '../../utils/analytics';
import Legal from '../Legal';

import './Download.scss';

const Download = ({ onClose }) => {
  const { store, updateStore } = useStore();
  const [validEmail, setValidEmail] = useState(!!store.emailAddress);
  const [imageUrls, setImageUrls] = useState();
  const emailRef = useRef(null);

  useEffect(() => {
    const getImageUrls = async () => {
      const activeTx = store.transformation?.at(-1);
      const urls = await Promise.all(
        store.imageData.map(async img => await getImagePath(img.publicId, activeTx))
      );
      setImageUrls(urls);
    };
    getImageUrls();
  }, [store.imageData, store.transformation]);

  const handleEmail = () => {
    const isValid = emailRef.current.checkValidity();
    updateStore({ emailAddress: isValid ? emailRef.current.value : '' });
    setValidEmail(isValid);
  };

  const handleDownload = async () => {
    downloadZip(imageUrls);

    sendAnalytics({ ...EVENTS.select_download_assets, additional_info: emailRef.current.value });

    updateStore({
      showDownload: false,
      showFeedback: true
    });
  };

  const toggleLegal = e => {
    e?.preventDefault();
    updateStore({ showLegal: !store.showLegal });
  };

  const setAgreed = value => {
    updateStore({ legalAgreement: value });
  };

  return (
    <Modal className="download" onClose={onClose}>
      <h2>Download all</h2>

      <div className="images">
        {imageUrls?.map((url, i) => (
          <img
            key={i}
            src={url}
            alt=""
            style={{ marginLeft: -50 + 50 / (imageUrls.length - 1) + '%' }}
          />
        ))}
      </div>

      <h3>Enter your email and hear about our new AI tools</h3>
      <div className="email">
        <input
          className="input email-input"
          type="email"
          ref={emailRef}
          defaultValue={store.emailAddress}
          placeholder="your@email.com"
          onChange={handleEmail}
          required
        />
      </div>

      <label className="agree">
        <input
          type="checkbox"
          checked={store.legalAgreement}
          onChange={e => setAgreed(e.target.checked)}
        />{' '}
        <Icon className="unchecked" path={mdiCheckboxBlankOutline} size={1} />
        <Icon className="checked" path={mdiCheckboxMarked} size={1} />
        <span>
          I agree to{' '}
          <a href="#legal" onClick={toggleLegal}>
            Cloudinary Labs Legal Disclaimer
          </a>
        </span>
      </label>

      <div className="actions">
        <button
          className="btn with-text"
          disabled={!store.legalAgreement || !validEmail}
          onClick={handleDownload}
          title={
            !store.legalAgreement || !validEmail
              ? 'You must agree to Cloudinary Labs Legal Disclaimer, and enter your Email'
              : ''
          }
        >
          <Icon path={mdiDownload} size={1} />
          Download
        </button>
        {/* <button
          className="btn inverted with-text"
          onClick={showShareResult}
          disabled={!store.legalAgreement}
          title={!store.legalAgreement ? 'You must agree to Cloudinary Labs Legal Disclaimer' : ''}
        >
          <Icon path={mdiSend} size={1} />
          Tell a friend
        </button> */}
      </div>

      {store.showLegal && <Legal onClose={toggleLegal} />}
    </Modal>
  );
};

export default Download;
