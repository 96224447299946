import { useStore } from './store';
import { isObject } from './utils';

// const dummyState = {
//   addShadow: true,
//   removeBackground: true,
//   background: {
//     publicId: 'id',
//   }
//   textOverflow: {
//     text: 'text',
//   },
//   imageOverflow: {
//     publicId: 'id'
//   }
// }

export function useTransformation() {
  const { store, updateStore } = useStore();
  const transformation = store.transformation;

  function setTransformation(newState) {
    const lastState = transformation[transformation.length - 1];
    const mergedState = isObject(newState) ? { ...lastState, ...newState } : newState;
    updateStore({
      transformation: [...transformation, mergedState],
    });
  }

  return { transformation, setTransformation };
}
