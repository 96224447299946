import React from 'react';
import { useStore } from '../../utils/store';
import Icon from '@mdi/react';
import { mdiCloseCircleOutline } from '@mdi/js';
import { getStrBetween } from '../../utils/utils';
import ImageLayer from '../ImageLayer/ImageLayer';
import { sendAnalytics, EVENTS } from '../../utils/analytics';
import './AssetsBar.scss';
import DropZone from '../DropZone/DropZone';

const AssetsBar = () => {
  const { store, updateStore } = useStore();

  const onDrop = uploadInfo => {
    sendAnalytics({...EVENTS.assets_upload_from_assets_bar, additional_info: uploadInfo.length,});

    updateStore({
      uploadInProgress: true,
      imageData: [...store.imageData, ...uploadInfo],
      activeIndex: store.imageData.length
    });
  };

  const onUpload = uploadInfo => {
    let batchFolderName;
    if (!store.batchFolderName) {
      batchFolderName = getStrBetween(uploadInfo[0].publicId, 'EditorAtScale/', '/');
    }

    updateStore({
      uploadInProgress: false,
      imageData: [...store.imageData, ...uploadInfo],
      ...(batchFolderName && { batchFolderName })
    });

    sendAnalytics({...EVENTS.assets_upload_from_assets_bar_done, additional_info: store.batchFolderName,});
  };

  const removeItem = i => {
    updateStore({
      imageData: store.imageData.toSpliced(i, 1),
      activeIndex:
        store.activeIndex >= i
          ? store.activeIndex - 1
          : store.activeIndex
    });
  };

  const setActive = i => {
    updateStore({
      activeIndex: i
    });
  };

  return (
    <div className="assets">
      {store.imageData.map((image, index) => (
        <div className={`asset ${store.activeIndex === index ? 'active' : ''}`} key={index}>
          <button className="remove" onClick={() => removeItem(index)}>
            <Icon path={mdiCloseCircleOutline} size={1} />
          </button>
          <button className="select" onClick={() => setActive(index)}>
            <ImageLayer publicId={image.publicId} />
          </button>
        </div>
      ))}
      <DropZone
        text=" "
        onDrop={onDrop}
        onUpload={onUpload}
        maxFiles={10 - (store.imageData?.length || 0)}
        {...(store.batchFolderName && { requestData: { batchFolderName: store.batchFolderName } })}
      />
    </div>
  );
};

export default AssetsBar;
