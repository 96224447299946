import React, { useMemo } from 'react';
import { createPortal } from 'react-dom';
import { Tooltip } from 'react-tooltip';
import { v4 as uuidv4 } from 'uuid';

import 'react-tooltip/dist/react-tooltip.css';
import './Tooltipped.scss';

const Tooltipped = ({ el = 'div', tooltipProps, ...props }) => {
  const id = useMemo(uuidv4, []);

  const El = el;

  return (
    <>
      <El className="tooltiped" data-tip-id={id} {...props}>
        {props.children}
      </El>
      {createPortal(
        <Tooltip
          anchorSelect={`[data-tip-id="${id}"]`}
          {...tooltipProps}
        />,
        document.body
      )}
    </>
  );
};

export default Tooltipped;
