export const EVENTS = {
  assets_upload: {
    event_name: 'assets_upload',
    level_2: 'welcome screen'
  },
  assets_upload_done: {
    event_name: 'assets_upload_done',
    level_2: 'welcome screen'
  },
  assets_upload_from_assets_bar: {
    event_name: 'assets_upload_from_assets_bar',
    level_2: 'asset editor',
    level_3: 'assets bar'
  },
  assets_upload_from_assets_bar_done: {
    event_name: 'assets_upload_from_assets_bar_done',
    level_2: 'asset editor',
    level_3: 'assets bar'
  },
  background_upload: {
    event_name: 'background_upload',
    level_2: 'asset editor',
    level_3: 'background panel'
  },
  background_upload_done: {
    event_name: 'background_upload_done',
    level_2: 'asset editor',
    level_3: 'background panel'
  },
  choose_example_images: {
    event_name: 'choose_example_images',
    level_2: 'welcome screen'
  },
  shadow_generation_toggle_on: {
    event_name: 'shadow_generation_toggle_on',
    level_2: 'asset editor',
    level_3: 'edit submenu'
  },
  shadow_generation_toggle_off: {
    event_name: 'shadow_generation_toggle_off',
    level_2: 'asset editor',
    level_3: 'edit submenu'
  },
  remove_background: {
    event_name: 'remove_background',
    level_2: 'asset editor',
    level_3: 'edit submenu'
  },
  clicked_generate_bg: {
    event_name: 'clicked_generate_bg',
    level_2: 'asset editor',
    level_3: 'edit submenu'
  },
  undo: {
    event_name: 'undo',
    level_2: 'asset editor',
    level_3: 'main menu'
  },
  redo: {
    event_name: 'redo',
    level_2: 'asset editor',
    level_3: 'main menu'
  },
  click_on_download: {
    event_name: 'click_on_download',
    level_2: 'asset editor',
    level_3: 'main menu'
  },
  select_download_assets: {
    event_name: 'select_download_assets',
    level_2: 'asset editor',
    level_3: 'download section'
  },
  select_rating: {
    event_name: 'select_rating',
    level_2: 'asset editor',
    level_3: 'feedback section'
  },
  submit_answer_to_feedback_question: {
    event_name: 'submit_answer_to_feedback_question',
    level_2: 'asset editor',
    level_3: 'feedback section'
  },
  submit_email_from_feedback: {
    event_name: 'submit_email_from_feedback',
    level_2: 'asset editor',
    level_3: 'feedback section'
  },
  sign_up_from_feedback: {
    event_name: 'sign_up_from_feedback',
    level_2: 'asset editor',
    level_3: 'feedback section'
  },
  sign_up_from_coming_soon: {
    event_name: 'sign_up_from_coming_soon',
    level_2: 'asset editor',
    level_3: 'coming soon section'
  },
  submit_email_from_coming_soon: {
    event_name: 'submit_email_from_coming_soon',
    level_2: 'asset editor',
    level_3: 'coming soon section'
  },
  click_share: {
    event_name: 'click_share',
    level_2: 'asset editor',
    level_3: 'main menu'
  },
  select_social_media_channel: {
    event_name: 'select_social_media_channel',
    level_2: 'asset editor',
    level_3: 'tell a friend section'
  },
  copy_website_url: {
    event_name: 'copy_website_url',
    level_2: 'asset editor',
    level_3: 'tell a friend section'
  },
  click_on_text_overlay: {
    event_name: 'click_on_text_overlay',
    level_2: 'asset editor',
    level_3: 'main menu'
  },
  click_on_image_overlay: {
    event_name: 'click_on_image_overlay',
    level_2: 'asset editor',
    level_3: 'main menu'
  },
  click_on_edit_background: {
    event_name: 'click_on_edit_background',
    level_2: 'asset editor',
    level_3: 'main menu'
  },
  click_sample_bg_image: {
    event_name: 'click_sample_bg_image',
    level_2: 'asset editor',
    level_3: 'background panel'
  },
  click_on_color_bg: {
    event_name: 'click_on_color_bg',
    level_2: 'asset editor',
    level_3: 'background panel'
  },
  click_on_color_picker: {
    event_name: 'click_on_color_picker',
    level_2: 'asset editor',
    level_3: 'background panel'
  },
  overlay_upload: {
    event_name: 'overlay_upload',
    level_2: 'asset editor',
    level_3: 'overlay panel'
  },
  overlay_upload_done: {
    event_name: 'overlay_upload_done',
    level_2: 'asset editor',
    level_3: 'overlay panel'
  },
  select_overlay: {
    event_name: 'select_overlay',
    level_2: 'asset editor',
    level_3: 'overlay panel'
  }
  // contact_navigate: {
  //   event_name: 'contact_navigate',
  //   level_2: 'welcome screen'
  // },
  // asset_proccessing: {
  //   event_name: 'asset_proccessing',
  //   level_2: 'welcome screen'
  // },
  // error_msg: {
  //   event_name: 'error_msg',
  //   level_2: 'welcome screen'
  // },
  // // item_selection: {
  // //   event_name: 'item_selection',
  // //   level_2: 'asset editor screen'
  // // },
  // asset_replace: {
  //   event_name: 'asset_replace',
  //   level_2: 'asset editor screen'
  // },
  // item_erase_undo: {
  //   event_name: 'item_erase_undo',
  //   level_2: 'asset editor screen'
  // },
  // item_erase_redo: {
  //   event_name: 'item_erase_redo',
  //   level_2: 'asset editor screen'
  // },
  // asset_view_original: {
  //   event_name: 'asset_view_original',
  //   level_2: 'asset editor screen'
  // },
  // item_erase: {
  //   event_name: 'item_erase',
  //   level_2: 'asset editor screen'
  // },
  // item_erase_progress: {
  //   event_name: 'item_erase_progress',
  //   level_2: 'asset editor screen'
  // },
  // asset_download: {
  //   event_name: 'asset_download',
  //   level_2: 'asset editor screen'
  // },
  // rating_form_submit: {
  //   event_name: 'rating_form_submit',
  //   level_2: 'asset editor screen'
  // },
  // feedback_text_submit: {
  //   event_name: 'feedback_text_submit',
  //   level_2: 'asset editor screen'
  // },
  // product_share: {
  //   event_name: 'product_share',
  //   level_2: 'welcome screen'
  // },
  // product_share_result: {
  //   event_name: 'product_share_result',
  //   level_2: 'asset editor screen'
  // },
  // product_share_social_intent: {
  //   event_name: 'product_share_social_intent',
  //   level_2: 'social share'
  // },
  // product_share_copy_link: {
  //   event_name: 'product_share_copy_link',
  //   level_2: 'social share'
  // }
};

export const sendAnalytics = ({
  event = 'ga4_event',
  level_1 = 'edit at scale',
  event_name,
  level_2,
  level_3,
  additional_info,
  additional_info2
}) => {
  window.dataLayer.push({
    event,
    event_name,
    level_1,
    ...(level_2 && { level_2 }),
    ...(level_3 && { level_3 }),
    ...(additional_info && { additional_info }),
    ...(additional_info2 && { additional_info2 })
  });
};
