import React, { memo, useState, useEffect } from 'react';
import Icon from '@mdi/react';
import { mdiAlertCircleOutline } from '@mdi/js';
import { useStore } from '../../utils/store';
import { getImagePath } from '../../utils/cloudinary';
import Tooltipped from '../Tooltipped/Tooltipped';

import './ImageLayer.scss';

const ImageLayer = ({ publicId, tx }) => {
  const { store } = useStore();
  const [src, setSrc] = useState('');
  const [fallback, setFallback] = useState('');
  const [loading, setLoading] = useState();
  const [error, setError] = useState(false);

  const transformation = tx || store.transformation?.at(-1) || 't_resize';

  useEffect(() => {
    const interval = 3000; // check every X second
    let times = 15; // give up after X checks
    let timeoutId = null;

    const checkImage = () => {
      setLoading(true);
      fetch(src, { method: 'HEAD' }).then(response => {
        if (response.status === 200) {
          setLoading(false);
          setError(false);
          clearTimeout(timeoutId);
        } else {
          if (times > 0) {
            times--;
            timeoutId = setTimeout(checkImage, interval);
          } else {
            setLoading(false);
            setError(true);
            clearTimeout(timeoutId);
            console.error(`HTTP error! status: ${response.status}`, response);
          }
        }
      });
    };

    if (src) {
      checkImage();
    }

    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [src]);

  // fetch placeholder path
  useEffect(() => {
    if (publicId) {
      getImagePath(publicId, 't_resize').then(imgSrc => imgSrc && setFallback(imgSrc));
    }
  }, [publicId]);

  // fetch image path
  useEffect(() => {
    if (publicId) setLoading(true);

    const fetchImagePath = async () => {
      let imgSrc;
      try {
        imgSrc = await getImagePath(publicId, transformation);
        setSrc(imgSrc);
      } catch (error) {
        setError(true);
        setLoading(false);
        console.error(error);
      } finally {
        if (!imgSrc) {
          setError(true);
          setLoading(false);
        }
      }
    };

    fetchImagePath();
  }, [publicId, transformation]);

  return (
    <>
      <div className={`loading-overlay ${loading ? 'visible' : 'hidden'}`}>
        <img
          src="https://res.cloudinary.com/demo/image/upload/docs/cloudinaryLoader.svg"
          alt="Loading"
        ></img>
      </div>
      <img src={loading ? fallback : src} alt="" className={loading ? 'fade-in-out' : ''} />
      {error && (
        <Tooltipped
          className="error"
          title="Last operation failed"
          tooltipProps={{
            content: 'Last operation failed',
            place: 'right'
          }}
        >
          <Icon title="Last operation failed" path={mdiAlertCircleOutline} size={1} />
        </Tooltipped>
      )}
    </>
  );
};

export default memo(ImageLayer);
