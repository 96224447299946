import { Cloudinary } from '@cloudinary/url-gen';
import { CLOUDNAME, APPLY_TRANSFORMATION_ENDPOINT, GENERATE_BACKGROUND_ENDPOINT } from '../const';
import { getStrBetween, getBgPath, setBgPath } from './utils';

const cld = new Cloudinary({
  cloud: {
    cloudName: CLOUDNAME
  }
});

const getSignedUrl = async (publicId, txSTR) => {
  const signed = await fetch(APPLY_TRANSFORMATION_ENDPOINT, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ publicId, transformation: txSTR })
  })
    .then(response => {
      if (response.status === 200) {
        return response.json();
      } else {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    })
    .catch(error => {
      console.error(error);
    });

  return signed.url;
};

export async function getImagePath(publicId, transform = {}) {
  const cldImage = cld.image(publicId);

  // Original
  if (transform?.background?.publicId === 'original' && !transform.addShadow) {
    cldImage.addTransformation('t_resize');
  }
  // Background removal
  else if (transform?.background?.publicId === 'none') {
    if (transform.addShadow) {
      cldImage.addTransformation('t_shadow');
    } else {
      cldImage.addTransformation('t_bg_remove');
    }
    // Color - Solid or Gradient
  } else if (transform?.background?.publicId === 'color') {
    if (transform.background.isRadial) {
      cldImage.addTransformation('e_background_removal');
      if (transform.addShadow) {
        cldImage.addTransformation('e_dropshadow');
      }
      cldImage.addTransformation('t_resize');
      cldImage.addTransformation(`u_${publicId.replaceAll('/', ':')}`);
      cldImage.addTransformation(`t_resize,co_rgb:${transform.background.color1},e_colorize`);
      cldImage.addTransformation(
        `e_gradient_fade:symmetric:10,x_0.3,y_0.3,b_rgb:${transform.background.color2}`
      );
      cldImage.addTransformation('fl_layer_apply,fl_no_overflow');
    } else if (transform.background.color2) {
      const direction = transform.background.isHorizontal ? 'x' : 'y';
      cldImage.addTransformation('e_background_removal');
      if (transform.addShadow) {
        cldImage.addTransformation('e_dropshadow');
      }
      cldImage.addTransformation('t_resize');
      cldImage.addTransformation(`u_${publicId.replaceAll('/', ':')}`);
      cldImage.addTransformation(`t_resize,co_rgb:${transform.background.color1},e_colorize`);
      cldImage.addTransformation(
        `e_gradient_fade,${direction}_-0.99,b_rgb:${transform.background.color2}`
      );
      cldImage.addTransformation('fl_layer_apply,fl_no_overflow');
    } else {
      cldImage.addTransformation('e_background_removal');
      cldImage.addTransformation(`t_resize,b_rgb:${transform?.background?.color1}`);
    }

    // return the URL substring between "upload/" & "/v1"
    const txSTR = getStrBetween(cldImage.toURL(), 'upload/', '/v1');
    const signedUrl = await getSignedUrl(publicId, txSTR);

    return signedUrl;
  }
  // Background generation
  else if (transform?.background?.prompt) {
    const txSTR = getStrBetween(cldImage.toURL(), 'upload/', '/v1');
    const cached = getBgPath(transform.background.bgId, `${publicId}-${txSTR}`);
    if (cached) return cached;
    if (!publicId) return;

    const res = await fetch(GENERATE_BACKGROUND_ENDPOINT, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        publicId,
        prompt: transform.background.prompt,
        bgId: transform.background.bgId
      })
    })
      .then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
      })
      .catch(error => {
        console.error(error);
        throw error;
      });

    const path = cld.image(res.generatedPublicId).addTransformation('t_resize').toURL();
    setBgPath(transform.background.bgId, `${publicId}-${txSTR}`, path);
    return path;
  }
  // Background replacement
  else if (transform?.background) {
    cldImage.addTransformation('e_background_removal');

    if (transform.addShadow) {
      cldImage.addTransformation('e_dropshadow');
    }
    cldImage.addTransformation('t_resize');
    if (transform.background && transform.background.publicId !== 'none') {
      const isOriginal = transform.background.publicId === 'original';
      const bgPublicId = isOriginal ? publicId : transform.background.publicId;
      if (bgPublicId) {
        cldImage
          .addTransformation(`u_${bgPublicId.replaceAll('/', ':')}`)
          .addTransformation(isOriginal ? 't_resize' : 't_resize_fill')
          .addTransformation('fl_layer_apply,fl_no_overflow,g_center');
      }
    }

    // return the URL substring between "upload/" & "/v1"
    const txSTR = getStrBetween(cldImage.toURL(), 'upload/', '/v1');
    const cached = getBgPath(transform.background.publicId, `${publicId}-${txSTR}`);
    if (cached) return cached;

    const signedUrl = await getSignedUrl(publicId, txSTR);

    setBgPath(transform.background.publicId, `${publicId}-${txSTR}`, signedUrl);
    return signedUrl;
  }
  // Specificaly set transformation, i.e. "t_thumb"
  else if (transform && typeof transform === 'string') {
    cldImage.addTransformation(transform);
  }
  // Fallback
  else {
    cldImage.addTransformation('t_resize');
  }

  if (transform.overlay && transform.overlay.publicId) {
    const overlayPublicId = transform?.overlay.publicId;
    const overlayPlacement = transform.overlay.placement || 'center';
    const overlaySize = Number(transform.overlay.size) / 100 || 0.5;
    const overlayOpacity = Number(transform.overlay.opacity) || 50;
    cldImage.addTransformation(`l_${overlayPublicId.replaceAll('/', ':')}`);
    cldImage.addTransformation(`c_scale,fl_relative,h_${overlaySize}`);
    cldImage.addTransformation(`o_${overlayOpacity}`);
    cldImage.addTransformation(`fl_layer_apply,g_${overlayPlacement},x_0.02,y_0.04`);

    const txSTR = getStrBetween(cldImage.toURL(), 'upload/', '/v1');
    const signedUrl = await getSignedUrl(publicId, txSTR);

    return signedUrl;
  }

  return cldImage.toURL();
}
