// export const CLOUDNAME = 'editor-at-scale';
export const CLOUDNAME = 'smart-ai-transformations-inpainting';

export const BASENAME = window.location.pathname.includes('/edit-at-scale')
  ? '/edit-at-scale'
  : '/';

export const PRODUCTION_CLIENT_URL =
  'https://production-smart-ai-transformations-bff.cloudinary.com/editor-at-scale';
export const STAGING_CLIENT_URL =
  'https://staging-smart-ai-transformations-bff.cloudinary.com/editor-at-scale';

const apiClientUrl =
  window.location.hostname === 'localhost' ? STAGING_CLIENT_URL : PRODUCTION_CLIENT_URL;

// export const UPLOAD_IMAGE_ENDPOINT = `${apiClientUrl}/upload-image`;
export const APPLY_TRANSFORMATION_ENDPOINT = `${apiClientUrl}/apply-transformation`;
export const UPLOAD_IMAGE_ENDPOINT = `${apiClientUrl}/upload-images`;
// export const REPLACE_BACKGROUND_ENDPOINT = `${apiClientUrl}/replace-background`;
export const GENERATE_BACKGROUND_ENDPOINT = `${apiClientUrl}/generate-background`;
export const GENERATE_FULL_BACKGROUND_ENDPOINT = `${apiClientUrl}/generate-full-image-background`;
export const SHARE_IMAGE_ENDPOINT = `${apiClientUrl}/shared-image`;

export const SHARE_URL = 'https://edit-at-scale.cloudinary.com';

// a simple array of [{ publicId: 'somepublicid' }, ...]
export const DEFAULT_ASSETS = [
  'EditorAtScale/c0094baf-d0d3-4b8b-9650-a72ce918ad6b/gae9fkabuukl46zfmgx8',
  'EditorAtScale/c0094baf-d0d3-4b8b-9650-a72ce918ad6b/vhwgowiuynkgbig8jijh',
  'EditorAtScale/c0094baf-d0d3-4b8b-9650-a72ce918ad6b/qzh0fi7jbmnxkz72rsdp'
].map(pid => ({
  publicId: pid
}));

// a simple array of [{ publicId: 'backgrounds/8' }, ...]
export const DEFAULT_BACKGROUNDS = [8, 9, 10, 11, 13, 14, 15, 16].map(num => ({
  publicId: `backgrounds/${num}`
}));
export const BACKGROUNDS = JSON.parse(localStorage.getItem('backgrounds')) || DEFAULT_BACKGROUNDS;
// a simple array of [{ publicId: 'overlays/8' }, ...]
export const DEFAULT_OVERLAYS = [4, 5, 6, 7, 8, 9].map(num => ({
  publicId: `overlays/${num}`
}));
export const OVERLAYS = JSON.parse(localStorage.getItem('overlays')) || DEFAULT_OVERLAYS;
