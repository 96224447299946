import React, { useRef } from 'react';
import Icon from '@mdi/react';
import { mdiSend } from '@mdi/js';
import { useStore } from '../../utils/store';
import { sendAnalytics, EVENTS } from '../../utils/analytics';
import './ComingSoon.scss';
import ImageLayer from '../ImageLayer/ImageLayer';

const ComingSoon = () => {
  const emailRef = useRef(null);
  const { store } = useStore();

  const handleEmail = () => {
    store.sidepanel === 'text'
      ? sendAnalytics({
          ...EVENTS.submit_email_from_coming_soon,
          additional_info: emailRef.current.value,
          additional_info2: 'text overlay'
        })
      : sendAnalytics({
          ...EVENTS.submit_email_from_coming_soon,
          additional_info: emailRef.current.value,
          additional_info2: 'image overlay'
        });

    emailRef.current.value = 'Thanks!';
    setTimeout(() => {
      emailRef.current.value = '';
    }, 3000);
  };

  const handleSignUpAnalytics = () => {
    store.sidepanel === 'text'
      ? sendAnalytics({ ...EVENTS.sign_up_from_coming_soon, additional_info: 'text overlay' })
      : sendAnalytics({ ...EVENTS.sign_up_from_coming_soon, additional_info: 'image overlay' });
  };

  return (
    <div className="coming-soon">
      <h3 className="title">This feature is coming soon...</h3>
      {store.sidepanel === 'text' ? (
        <ImageLayer publicId="EditorAtScale/c0094baf-d0d3-4b8b-9650-a72ce918ad6b/v99bsqosmoiyms1bm13s" tx="t_resize" />
      ) : (
        <ImageLayer publicId="EditorAtScale/6ffe44e3-a33a-42b8-8a3d-3e30312b6edf/yalmp6f0d01fqw9nqo6u" tx="t_resize" />
      )}
      <h3 className="sign-up-title">Sign up for updates</h3>
      <h5>And we’ll send you a mail when its available </h5>
      <div className="email">
        <input
          className="input email-input"
          type="email"
          ref={emailRef}
          placeholder="your@email.com"
          defaultValue={store.emailAddress}
          required
        />
        <button className="btn inverted send-email" onClick={handleEmail}>
          <Icon path={mdiSend} size={1} />
        </button>
      </div>

      <a
        href="https://cloudinary.com/users/register_free?ref=edit_at_scale"
        rel="noreferrer noopener"
        target="_blank"
        className="sign-up"
        onClick={handleSignUpAnalytics}
      >
        Free sign up to Cloudinary
      </a>
    </div>
  );
};

export default ComingSoon;
