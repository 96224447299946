import React from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import Icon from '@mdi/react';
import { mdiShareVariant, mdiForumOutline } from '@mdi/js';
import { sendAnalytics, EVENTS } from '../../utils/analytics';
import { useStore } from '../../utils/store';
import Share from '../Share';
import Feedback from '../Feedback';
import Tooltipped from '../Tooltipped';
import Download from '../Download';

import logo from './logo.svg';
import './Header.scss';

const Header = () => {
  const { store, updateStore } = useStore();

  const showShare = () => {
    sendAnalytics(EVENTS.click_share);
    updateStore({ showShare: true });
  };

  const hideShare = () => {
    updateStore({ showShare: false, showShareResult: false });
  };

  const toggleFeedback = () => {
    updateStore({ showFeedback: !store.showFeedback });
  };

  const toggleDownload = () => {
    sendAnalytics(EVENTS.click_on_download);
    updateStore({ showDownload: !store.showDownload });
  };

  return (
    <header className="header">
      <div className="header-inner">
        <Link className="brand" to="/">
          <img className="brand-logo" src={logo} alt="" />
          <span className="brand-name">Cloudinary</span>
          <span className="brand-labs">LABS</span>
          <span className="brand-slogan">Edit @ scale</span>
        </Link>

        <Routes>
          <Route
            exact
            path="edit"
            element={
              <>
                <Tooltipped
                  el="button"
                  className="btn download"
                  title="Download all as zip"
                  onClick={toggleDownload}
                  tooltipProps={{
                    content: 'Download all as zip',
                    place: 'bottom'
                  }}
                >
                  <span>Download</span>
                </Tooltipped>
                <Tooltipped
                  el="button"
                  className="feedback"
                  title="Feedback"
                  onClick={toggleFeedback}
                  tooltipProps={{
                    content: 'Feedback',
                    place: 'bottom'
                  }}
                >
                  <Icon path={mdiForumOutline} size={1} />
                </Tooltipped>
              </>
            }
          />
        </Routes>

        <Tooltipped
          el="button"
          className="share"
          title="Share"
          onClick={showShare}
          tooltipProps={{
            content: 'Share',
            place: 'bottom'
          }}
        >
          <Icon path={mdiShareVariant} size={1} />
        </Tooltipped>

        {(store.showShare || store.showShareResult) && <Share onClose={hideShare} />}
        {store.showFeedback && <Feedback onClose={toggleFeedback} />}
        {store.showDownload && <Download onClose={toggleDownload} />}
      </div>
    </header>
  );
};

export default Header;
